<template>
  <div>
    <div class="row">
      <div class="col-12">
        <div class="d-flex justify-content-end mt-2">
          <b-button variant="primary" @click="list"> Refresh </b-button>
        </div>
      </div>
    </div>
    <b-table striped hover class="mt-5" :items="items" :fields="fields">
      <template #cell(no)="data">
        {{ data.index + 1 }}
      </template>

      <template #cell(bedId)="data">
        {{ data.item.bed_id }}
      </template>

      <template #cell(patient_id)="data">
        {{ data.item.patient_nik }}
      </template>

      <template #cell(bed)="data">
        {{ data.item.bed_name }}
      </template>

      <template #cell(patient)="data">
        {{ data.item.patient_name }}
      </template>

      <template #cell(allotedTime)="data">
        {{ handleDate(data.item.date_in) }}
      </template>

      <template #cell(dischargeTime)="data">
        {{ handleDate(data.item.date_out) }}
      </template>

      <template #cell(status)="data">
        <div v-if="data.item.is_used == 1">
          <b-button
            size="sm"
            class="mr-1 btn-danger"
            @click="handleEdit(data.item.id)"
            >Kosongkan</b-button
          >
        </div>
        <div v-if="data.item.is_used == 0">
          <b-button size="sm" class="mr-1 btn-primary">Selesai</b-button>
        </div>
      </template>
    </b-table>
  </div>
</template>
<script>
import Card from "@/view/content/Card.vue";
import module from "@/core/modules/CrudModule.js";
import moment from "moment";

export default {
  name: "List",

  components: {
    Card,
  },

  props: {
    bedData: Number,
  },

  data() {
    return {
      fields: [
        {
          key: "no",
          label: "No",
          sortable: true,
        },
        {
          key: "bedId",
          label: "Kode Bangsal",
          sortable: true,
        },
        {
          key: "patient_id",
          label: "NIK Pasien",
          sortable: true,
        },
        {
          key: "patient",
          label: "Nama Pasien",
          sortable: true,
        },
        {
          key: "bed",
          label: "Nama Bangsal",
          sortable: true,
        },
        {
          key: "allotedTime",
          label: "Waktu Masuk",
          sortable: true,
        },
        {
          key: "dischargeTime",
          label: "Waktu Keluar",
          sortable: true,
        },
        {
          key: "status",
          label: "Status",
          sortable: true,
        },
      ],
      items: [],
    };
  },

  methods: {
    handleDate(date) {
      return moment(date).format("DD-MM-YYYY");
    },

    async list() {
      this.items = await module.list(
        "used-beds-history/" + this.bedData + "/patient"
      );
    },
  },

  mounted() {
    this.list();
  },
};
</script>